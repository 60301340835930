<script>
	import Layout from "@/router/layouts/main";
	import appConfig from "@/app.config";
	import PageHeader from "@/components/page-header";
	import axios from "axios";
	import Swal from "sweetalert2";
	import $ from "jquery"
	// import moment from 'moment';
	/**
	 * Dashboard Component
	 */
	export default {
		page: {
			title: "Preview Pengajuan Permohonan",
			meta: [
				{
					name: "description",
					content: appConfig.description,
				},
			],
		},
		components: {
			Layout,
			PageHeader,
		},
		data() {
			return {
				title: "Preview Pengajuan Permohonan",
				items: [
                    {
                        text: "Master",
                        href: "/",
                    },
                    {
                        text: "Sub Perizinan",
                        href: "/master/sub-prizinan",
                    },
                    {
                        text: "Preview Pengajuan Permohonan",
                        active: true,
                    },
				],
				perizinan_id: this.$route.query.perizinan_id,
				perizinan_sub_id: this.$route.query.perizinan_sub_id,
				// variabel kebutuhan form
				currentTabIndex: 0,
				html_form: null,
				konfig_dokumen: [],
				setuju_permohonan: false,
				// Data Umum
				nomor_surat : null,
				tgl_surat: null,
				perihal: null,
				permohonan_id: null,
				permohonan_dokumen: [],
				list_document: [],
				wizard_now : 'data-umum',
				wizard_next : '',
				width_garis : 2,
				act_wizard : '',
				page_preview: '',
				data_disclaimer: []
			};
		},
		mounted() {
			let self = this;
			let recaptchaScript = document.createElement('script')
			recaptchaScript.setAttribute('src', process.env.VUE_APP_BACKEND_URL+'js/permohonan/' + self.perizinan_sub_id + '/add.js')
			document.head.appendChild(recaptchaScript)
			axios.get(process.env.VUE_APP_BACKEND_URL_VERSION + "permohonan/load-add", {
				params:{
					perizinan_id: this.perizinan_id,
					perizinan_sub_id: this.perizinan_sub_id
				},
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + localStorage.access_token,
				},
			}).then((response) => {
				self.html_form = response.data;
			})
			.catch(e => {
				this.axiosCatchError = e.response.data.data
				console.log(this.e)
			});

			// Get konfig Dokumen
			axios.get(process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/load-dokumen-konfig", {
				params:{
					perizinan_id: this.perizinan_id,
					perizinan_sub_id: this.perizinan_sub_id
				},
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + localStorage.access_token,
				},
			}).then((response) => {
				var response_data = response.data;
				var response_data_fix = response_data.data;
				if(response_data.meta.code == 200){
					var raw_document = response_data_fix.referensi;
					var list_document = [];
					var permohonan_dokumen = [];
					$.each(raw_document, function(index, val) {
						var konfig = {
							'mdpk_id': val.mdpk_id,
							'mdp_id': val.dokumen_persyaratan.mdp_id,
							'mdp_nama': val.dokumen_persyaratan.mdp_nama,
							'mdp_is_isian_custom': val.dokumen_persyaratan.mdp_is_isian_custom,
							'mdp_label_isian_custom': val.dokumen_persyaratan.mdp_label_isian_custom,
							'mdp_is_tanggal_awal': val.dokumen_persyaratan.mdp_is_tanggal_awal,
							'mdp_is_tanggal_berakhir': val.dokumen_persyaratan.mdp_is_tanggal_berakhir,
						};
						// Untuk Listing Form Nya
						list_document.push(konfig);
						// Untuk Dikirim Ke Backend
						permohonan_dokumen.push({
							'pd_id': null,
							'pd_p_id': null,
							'pd_mdp_id': null,
							'pd_mdp_tanggal_awal': null,
							'pd_mdp_tanggal_akhir': null,
							'pd_mdp_label_isian_custom': null,
							'pd_mdp_isian_custom': null,
							'pd_path': null,
							'file': null,
						});
					});
					this.permohonan_dokumen = permohonan_dokumen;
					this.list_document = list_document;
				}else{
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: response_data_fix.data.message
					});
				}
			})
			.catch(e => {
				this.axiosCatchError = e.response.data.data
				console.log(this.axiosCatchError)
			}); 
			
			// get disclaimer konfig
			var config_data_disclaimer = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-sub/get-disclaimer",
                params:{
                    mps_id: self.perizinan_sub_id
                },
                headers:{
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                }
            }
            axios(config_data_disclaimer).then(function (response){
                var response_data = response.data;
                var response_data_fix = response_data.data.konfig;
                if(response_data.meta.code == 200){
                    let clear_data = [];
					$.each(response_data_fix, function (indexInArray, valueOfElement) { 
						clear_data.push({
							'disclaimer': valueOfElement.disclaimer,
							'urutan': valueOfElement.mdk_urutan
						})
					});
                    self.loadingTable = false;
                    self.data_disclaimer = clear_data;
                    console.log(self.data_disclaimer);
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response_data_fix.data.message
                    });
                }
            }).catch(function (error){
                console.log(error)
            });
		},
		methods:{
			// Wizard
			changeWizard(target, aksi) {
				// if(this.wizard_now == 'data-umum'){
				// 	this.storeDataUmum(target, aksi);
				// }
				// else if(this.wizard_now == 'data-teknis'){
				// 	this.storeDataTeknis(target, aksi);
				// }
				// else if(this.wizard_now == 'dokumen-persyaratan'){
				// 	this.storeDokumen(target, aksi);
				// }
				// else{
				// 	this.processChangeWizard(target, aksi);	
				// }
				this.processChangeWizard(target, aksi);	
			},
			
			processChangeWizard(target, aksi) {
				$('#' + target + '-parent').addClass('current');
				$('#' + this.wizard_now).removeClass('show active');
				$('#' + target).addClass('show active');
				if (aksi == 'next') {
					this.width_garis += 33;
					$('#line-wizard').css('width', this.width_garis + '%');
				} else {
					this.width_garis -= 33;
					$('#line-wizard').css('width', this.width_garis + '%');
				}
				this.wizard_now = target;
			},
			ajukanPerizinan(save_as){
                let timerInterval;
				Swal.fire({
                    icon: "success",
                    title: "Berhasil",
                    text: "Submit Permohonan - "+save_as,
                    timer: 2000,
                    timerProgressBar: true,
                    showCancelButton: false,
                    showConfirmButton: false,
                    willClose: () => {
                        clearInterval(timerInterval);
                    },
                }).then((result) => {
                    /* Read more about handling dismissals below */
                    console.log(result)
                });
			}
		}
	};
</script>
<template>
	<Layout>
		<PageHeader :title="title" />
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-header top-radius-15 bg-success"><h4 class="my-0 text-center text-white"><b>Pengajuan Permohonan</b></h4></div>
					<div class="card-body">
						<div class="row">
							<div class="col-md-12 steps">
								<ul role="tablist">
									<li role="tab" class="first current" aria-disabled="false" aria-selected="true" id="data-umum-parent">
										<a id="data-umum-tab" data-toggle="tab" data-target="#data-umum" type="button" role="tab" aria-controls="data-umum" aria-selected="true">
											<span class="current-info audible"> </span>
											<div class="title">
												<span class="title-text">
													Data Umum
												</span>
												<span class="title-number">01</span>
											</div>
										</a>
									</li>
									<li role="tab" class="done" aria-disabled="false" aria-selected="false" id="data-teknis-parent">
										<a id="data-teknis-tab" data-toggle="tab" data-target="#data-teknis" type="button" role="tab" aria-controls="data-teknis" aria-selected="false">
											<div class="title">
												<span class="title-text">
													Data Teknis
												</span>
												<span class="title-number">02</span>
											</div>
										</a>
									</li>
									<li role="tab" class="done" aria-disabled="false" aria-selected="false" id="dokumen-persyaratan-parent">
										<a id="dokumen-persyaratan-tab" data-toggle="tab" data-target="#dokumen-persyaratan" type="button" role="tab" aria-controls="dokumen-persyaratan" aria-selected="false">
											<div class="title">
												<span class="title-text">
													Dokumen Persyaratan
												</span>
												<span class="title-number">03</span>
											</div>
										</a>
									</li>
									<li role="tab" class="last done" aria-disabled="false" aria-selected="false" id="preview-parent">
										<a id="preview-tab" data-toggle="tab" data-target="#preview" type="button" role="tab" aria-controls="preview" aria-selected="false">
											<div class="title">
												<span class="title-text">
													Preview
												</span>
												<span class="title-number">04</span>
											</div>
										</a>
									</li>
									<div class="garis" id="line-wizard"></div>
								</ul>
							</div>
							<div class="col-md-12">
								<div class="tab-content mt-3" id="myTabContent">
									<div class="tab-pane fade tab-pengajuan show active" id="data-umum" role="tabpanel" aria-labelledby="data-umum-tab">
										<div class="card">
											<div class="card-body">
												<div class="row">
													<div class="col-md-12">
														<table class="table table-sm table-borderless">
															<tr>
																<th style="width: 250px;">Nomor Surat Permohonan <span class="text-danger">*</span></th>
																<td>
																	<input class="form-control" v-model="nomor_surat" placeholder="Nomor Surat Permohonan" />
																</td>
															</tr>
															<tr>
																<th style="width: 250px;">Tanggal Surat Permohonan <span class="text-danger">*</span></th>
																<td>
																	<input type="date" class="form-control" v-model="tgl_surat">
																</td>
															</tr>
															<tr>
																<th style="width: 250px;">Perihal</th>
																<td>
																	<textarea class="form-control" v-model="perihal" placeholder="Perihal"></textarea>
																</td>
															</tr>
														</table>
													</div>
												</div>
											</div>
											<div class="card-body">
												<div class="float-end">
													<div class="btn btn-success btn-sm btn-send" v-on:click="changeWizard('data-teknis', 'next')">
														Lanjutkan <i class="fa fa-arrow-right"></i>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="tab-pane fade tab-pengajuan" id="data-teknis" role="tabpanel" aria-labelledby="data-teknis-tab">
											<div class="card">
												<div class="card-body">
													<div class="row">
														<div class="col-md-12">
															<div v-html="html_form"></div>
														</div>
													</div>
												</div>
												<div class="card-footer">
													<div class="btn btn-secondary btn-sm" v-on:click="changeWizard('data-umum', 'prev')">
														<i class="fa fa-arrow-left"></i> Kembali
													</div>
													<div class="float-end">
														<div class="btn btn-success btn-sm btn-send" v-on:click="changeWizard('dokumen-persyaratan', 'next')">
															Lanjutkan <i class="fa fa-arrow-right"></i>
														</div>
													</div>
												</div>
											</div>
									</div>
									<div class="tab-pane fade tab-pengajuan" id="dokumen-persyaratan" role="tabpanel" aria-labelledby="dokumen-persyaratan-tab">
										<div class="card">
											<div class="card-body">
												<div class="row">
													<div class="col-md-6" v-for="(list_document_val, list_document_key) in list_document" :key="list_document_key">
														<div class="card shadow-lg rounded">
															<div class="card-body">
																<h5 class="text-center"><b>{{ list_document_val.mdp_nama }} </b></h5>
																<div class="row">
																	<div class="col-md-12" v-if="list_document_val.mdp_is_isian_custom == 't'">
																		<label for="">{{ list_document_val.mdp_label_isian_custom }} <span class="text-danger">*</span></label>
																		<input type="text" v-model="permohonan_dokumen[list_document_key].pd_mdp_isian_custom" class="form-control">
																	</div>
																	<div class="col-md-6" v-if="list_document_val.mdp_is_tanggal_awal == 't'">
																		<label for="">Tanggal Awal <span class="text-danger">*</span></label>
																		<input type="date" class="form-control" v-model="permohonan_dokumen[list_document_key].pd_mdp_tanggal_awal">
																	</div>
																	<div class="col-md-6" v-if="list_document_val.mdp_is_tanggal_berakhir == 't'">
																		<label for="">Tanggal Akhir <span class="text-danger">*</span></label>
																		<input type="date" class="form-control" v-model="permohonan_dokumen[list_document_key].pd_mdp_tanggal_akhir">
																	</div>
																	<div class="col-md-12">
																		<label for="">File  <span class="text-danger" v-if="list_document_val.mdp_is_mandatori == 't'">*</span></label>
																		<input type="file" class="form-control mb-0" :id="'files-doc-'+list_document_key" v-on:change="inputFileDocument(list_document_key)">
																		<div class="respond-input-file float-left" :id="'duloading-'+list_document_key">
																			<!-- <div target="_blank" class="hover-pointer" ><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat Dokumen</span></div> -->
																		</div><br>
																		<small class="float-right">*) Max File 2 MB</small>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="card-body">
												<div class="btn btn-secondary btn-sm" v-on:click="changeWizard('data-teknis', 'prev')">
													<i class="fa fa-arrow-left"></i> Kembali
												</div>
												<div class="float-end">
													<div class="btn btn-success btn-sm btn-send" v-on:click="changeWizard('preview', 'next')">
														Lanjutkan <i class="fa fa-arrow-right"></i>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="tab-pane fade tab-pengajuan" id="preview" role="tabpanel" aria-labelledby="preview-tab">
										<div class="card">
											<div class="card-body">
												<div class="row">
													<div class="col-md-12">
														<div v-html="page_preview"></div>
													</div>
													<div class="col-md-12">
														<b>Disclaimer</b>
														<table class="table mb-0 table-condensed">
															<tr v-for="(row_data_disclaimer, key_disclaimer) in data_disclaimer" :key="key_disclaimer">
																<td class="text-center" style="width:5%;">
																	{{row_data_disclaimer.urutan}}.
																</td>
																<td>
																	{{ row_data_disclaimer.disclaimer.md_nama }}
																</td>
															</tr>
														</table>
														<hr>
														<div class="form-group form-check">
															<input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="setuju_permohonan">
															<label class="form-check-label" for="exampleCheck1">Saya menyetujui data permohonan diatas untuk diproses</label>
														</div>
													</div>
												</div>
											</div>
											<div class="card-body">
												<div class="btn btn-secondary btn-sm" v-on:click="changeWizard('dokumen-persyaratan', 'prev')">
													<i class="fa fa-arrow-left"></i> Kembali
												</div>
												
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>